<template>
  <div class="container" style="margin-top: 200px;">
    <div class="accordion accordion-item" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h4>1. 這樣做會懷孕嗎</h4>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                <h2># 我只是愛撫私處，但是沒有性交會懷孕嗎？</h2>
                <p>透過手部的方式進行接觸，生殖器沒有直接接觸，那麼微量精子要在空氣中仍然保持活性是較為困難的。
通常發生這樣的狀況，唯有透過「驗孕」的方式才能知道有沒有懷孕喲！</p>
              </li>
              <li>
                <h2>
                  # 我如果保險套突然破掉了會懷孕嗎？體外射精還會懷孕嗎？
                </h2>
                <p>
                  男生生殖器官在興奮的狀態下，龜頭前端會分泌所謂的”前列腺液”，這個液體當中將含有精子的成分。
因此，在未採取安全措施下的性行為皆不存在避孕效果，即使是體外射精的情形，也可能因男性在興奮時所分泌的前列腺液與女性接觸而產生懷孕的可能。
而所謂「體外射精」也是一種成功率較低的避孕方式，我們非常不建議以此進行避孕，同時，在未正確配戴保險套的狀況下，也將使得性病的傳播無法被預防！
                </p>
              </li>
              <li>
                <h2># 在安全期/月經來時進行性行為還會懷孕嗎？</h2>
                <p>大部份的人月經週期為28－30天，以28天為一週期來算，月經來的第14天為排卵日，排卵日的前5天後4天，是『危險期』，除月經來潮的3－5天外，其餘的是『 安全期』。
所謂排卵期、安全期主要是協助備孕者找出容易受孕的時間點，進而提高成功懷孕的機會，而現代生活壓力較大，時而會影響女生的生理期時間，故也難以判定準確性，因此並不適合作為避孕的方法喔！
不過要提醒，目前市面上並無百分之百的避孕產品，即使透過避孕率最高的雙重避孕法(全程配戴保險套+服用事前避孕藥)也可能產生懷孕風險的喔。
我們會建議女方能在性行為後的18天或是經期延遲一週沒有來時進行驗孕，驗孕棒可至藥局或藥妝店購得，如果身體狀況出現不適症狀，建議儘快就醫確認生理狀況喲！</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <h4>2. 我想知道我是不是懷孕了</h4>
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <h2># 最近感覺胸部腫脹、頭痛、反胃的症狀是懷孕了嗎？還是只是月經快來呢？我月經沒來就是懷孕了嗎？</h2>
            <ul>
              <li>
                <p>目前沒有100%的避孕方式，有時候可能會因為性行為結束擔心懷孕，心理壓力很大導致月經遲來。
但也有可能真的懷孕囉！建議可以自行驗孕或是到婦產科就醫，會比較安心喔！
驗孕的時間點通常是性行為18天或是月經沒有來一週後，到藥局、藥妝店、便利商店購買驗孕棒/驗孕試紙確認。</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <h4>3. 避孕藥/保險套如何取得</h4>
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                <h2># 可以買事後避孕藥避孕嗎？</h2>
                <p>目前沒有100%的避孕方式，有時候可能會因為性行為結束擔心懷孕，心理壓力很大導致月經遲來。
但也有可能真的懷孕囉！建議可以自行驗孕或是到婦產科就醫，會比較安心喔！
驗孕的時間點通常是性行為18天或是月經沒有來一週後，到藥局、藥妝店、便利商店購買驗孕棒/驗孕試紙確認。</p>
              </li>
              <li>
                <h2># 我滿16歲但未滿18歲可以自己拿事後避孕藥嗎？</h2>
                <p>1.當你未滿18歲(法定成年)前，有關醫療的相關決定，都必須有監護人共同面對。如果你尚未和父母、監護人坦承懷孕，也可以透過社工的服務，陪伴你面對這個家庭協商的過程。</p>
                <br />
                <p>2.若未滿18歲時，父母是有義務負擔子女的行為責任，因為在民法上18歲以前的青少男女都是限制行為能力人，因此他們除了有義務維繫子女的經濟狀況外，也有義務照顧子女在非預期懷孕下所生的小孩。</p>
              </li>
              <li>
                <h2># 可以買事後避孕藥避孕嗎？</h2>
                <p>如果要進行人工流產，必須先確認懷孕週數，在現行優生保健法的規定內，超過24週是無法進行手術的。
另外，但有關於生育的決定，監護權人和當事人的意見都同等重要，不論要繼續懷孕或是人工流產，都必須取得共同決定。 法律上為了保護未滿16歲的青少年們身心健康，規範這個年齡層不適合發生性行為，因此當診所、醫院知道這個狀況，就保護青少年的立場，還是會通知社會局社工人員關懷喔。</p>
              </li>
              <li>
                <h2># 懷孕週數怎麼算？</h2>
                <p>從上次的經期結束最後一天開始推算。</p>
              </li>
              <li>
                <h2># 了解更多避孕方式</h2>
                <p><a href="https://www.beok.org.tw/other-method/" target="_blank"><span  style="color: red;font-weight: 500;">>> 避孕諮詢室</span></a></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFour">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <h4>4. 該怎麼和爸媽說</h4>
          </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                <h2># 未滿 18 歲，得知可能懷孕時該怎麼辦？該怎麼和父母說？</h2>
                <p>父母在第一時間聽到這個消息，一定跟你一樣擔心害怕，有時候甚至會很生氣。但是這些情緒的背後，都是因為擔憂你們在這個年紀要面對這麼困難的抉擇。或許在第一時間要和父母有共識並不容易，但是多數父母在最後都很願意幫助自己的孩子，若你在和父母溝通上有困難，歡迎與社工人員聯繫或是和勵馨基金會服務據點聯繫。
                <br>
                <br>勵馨基金會服務據點：https://www.goh.org.tw/location/
                <br>未成年懷孕求助網站：257085.sfaa.gov.tw
                <br>免付費諮詢專線：0800-25-7085 （服務時間為週一到週五09:00～18:00）</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <h4>5. 我有其他問題</h4>
          </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul>
              <li>
                <h2># 我有其他問題</h2>
                <p>當你決定生下寶寶，將會面臨經濟、就業、照顧與托育等問題，你可以向地方社會局尋求協助，因為每個縣市會有不同的福利或補助，社會局可根據你的情況，幫你找尋最好的方案。政府有統一規定育兒津貼與補助方案，而各個縣市也會根據情況提出不同的方案，你可以和勵馨基金會服務據點聯繫 goh.org.tw/location 或透過「未成年懷孕求助網站 | 257085.sfaa.gov.tw」的福利百寶箱，這個網站統整許多資源，例如經濟、育兒、就學、就業、醫療等資源，定期更新完整的資訊，方便你事先了解可以申請哪些資源與補助。</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.accordion-item {
  max-width: 1024px;
  margin: 0 auto;
  letter-spacing: 1.5px;
  line-height: 1.5;
  h2 {
      font-size: 20px;
      font-weight: bold;
      color:#9D4CFF
    }
  li {
    padding: 10px 0;
  }
  h4 {
    color:#9D4CFF;
    font-size: 20px;
    font-weight: bold;
  }
}

</style>
